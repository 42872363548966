import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setClientReportPages,
  setUserData,
  setSuccesMessage,
  setErrorMessage

} from "../../../store/features/generalSlice";
import { GetReportPages } from "../../../services";
import { createUserFav, exportResource } from "../../../services/user";
import Accordion from "react-bootstrap/Accordion";

import AlertMessage from "../../../components/admin/AlertMessage";
import AddMyFavourites from "./AddMyFavourites";

import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import PageAccordion from "./PageAccordion";
import ResourceInfoModal from "./ResourceInfoModal";
import { Link, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";

import Eye from "../../../assets/theme/images/eye.png";
import Export from "../../../assets/theme/images/export.png";

import {
  Info,
  Pin,
  Edit,
  TrashTwo,
  ExportIcn,
  ReportOne,
  LoaderAcc,
  Reports,
  ReportTwo,
} from "../../../assets/theme/images/icons";

function CustomToggle({
  children,
  eventKey,
  workspaceId,
  clientId,
  resourceId,
}) {
  //console.log("resourceId", resourceId);
  const [data, setData] = useState([]);

  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    getReportPages(resourceId)
  );
  async function getReportPages(resourceId) {
    let user = false;
    user = {
      token: userData.accessToken,
    };
    let ignore = false;
    const response = await GetReportPages(resourceId, user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );

    if (!ignore) {
      try {
        let temp = [];
        const clientReportPages = response.data;
        clientReportPages.forEach(function (reportPage) {
          const str = reportPage.displayName;
          if (!str.startsWith(" ")) {
            temp.push(reportPage);
          }
        });
        dispatch(setClientReportPages({ clientReportPages: temp }));
      } catch (errors) {
        console.log(errors);
      }
      //dispatch(setClientReportPages({ clientReportPages: response.data }));
      setShowLoader(false);
    }
  }

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink", width: "auto" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function AllReportAccordion({ workspaceId = null, clientId = null }) {
  const [name, setName] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const userData = useSelector((state) => state.general.userData);
  //console.log("AllReportAccordion", workspaceId, clientId);
  const general = useSelector((state) => state.general);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let clientReports = [];

  let ignore = false;

  clientReports =
    general?.typewiseResources && general?.typewiseResources["Report"]
      ? general.typewiseResources["Report"]
      : [];
  function showDescriptionModal(item) {
    console.log("showDescriptionModal");
    setShowModal(true);
    setModalData(item);
  }
  const handleClose = () => setShowModal(false);
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  async function exportReport(resourceId) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let params = {
      fileFormat: 1,
      resourceType: "Report",
    };
    exportResource(resourceId, params, user)
      .then((res) => {
        let succesMessage = "Export request completed successfully.";
        // localStorage.setItem("alertMessage", succesMessage);
        setAlertMessage(succesMessage);
        //navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  }
  useEffect(() => {
    
    dispatch(
      setSuccesMessage({
        succesMessage   : false,
      })
    );
    dispatch(
      setErrorMessage({
        errorMessage   : false,
      })
    );
  }, []);

  return (
    <Accordion defaultActiveKey="">
      {alertMessage && (
        <AlertMessage alertMessage={alertMessage} alertType={"success"} />
      )}
      {error && <AlertMessage alertMessage={error} alertType={"error"} />}
      {general?.succesMessage && (
        <AlertMessage alertMessage={general?.succesMessage} alertType={"success"} />
      )}
      {general?.errorMessage && <AlertMessage alertMessage={general?.errorMessage} alertType={"error"} />}
      
      {clientReports &&
        clientReports.length > 0 &&
        clientReports.map((item) => (
          <Card key={item.id}>
            <Card.Header>
              <div class="d-flex align-items-center">
                <CustomToggle
                  eventKey={item.id}
                  workspaceId={workspaceId}
                  clientId={clientId}
                  resourceId={item.id}
                >
                  <ReportTwo />
                </CustomToggle>
                <a href={`/user/embeded-view/${item.id}`}>
                  {item.aliasName ? item.aliasName : item.name}
                </a>
              </div>
              <div className="accordion-actions">
                <span>
                  {item?.lastRefresh?.startTime ? (
                    <Moment tz="Asia/Kolkata">
                      {item?.lastRefresh?.startTime}
                    </Moment>
                  ) : (
                    ""
                  )}
                </span>
                <div className="buttons">
                  <a href={`/user/embeded-view/${item.id}`}>
                    <img src={Eye} alt="" /> View
                  </a>
                </div>
                <div className="icons">
                  <Link onClick={() => showDescriptionModal(item)}>
                    <Info />
                  </Link>
                  <AddMyFavourites itemId={item.id} resourceType={"Report"} />

                  <Link onClick={() => exportReport(item.id)}>
                    <ExportIcn />
                  </Link>
                  {/* <a href="#">
                    <Edit />
                  </a>
                  <a href="#">
                    <TrashTwo />
                  </a> */}
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={item.id}>
              <Card.Body className="page-accordion-wrap">
                <PageAccordion />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      {clientReports && clientReports.length <= 0 && !showLoader && (
        <Card>
          <Card.Body className="page-accordion-wrap">
            No Result found!
          </Card.Body>
        </Card>
      )}
      <ResourceInfoModal
        show={showModal}
        handleClose={handleClose}
        modalData={modalData}
      />
    </Accordion>
  );
}

export default AllReportAccordion;
